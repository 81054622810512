<template>
  <div class="jyh-footer view-padding">
    <div class="line">
      地址：上海市杨浦区国定东路200号4号413室
    </div>
    <div class="line">
      银杏叶公众微信号：YXYeHH
    </div>
    <!-- <div class="line" v-if="version==='pubmedo'">
      备案号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #222; margin-left:5px;">沪ICP备13043474号-4</a>
    </div>
    <div class="line" v-if="version==='pmoppub'">
      备案号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #222; margin-left:5px;">沪ICP备13043474号-5</a>
    </div> -->
    <div class="line" v-if="version==='iyxy'">
      备案号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #222; margin-left:5px;">沪ICP备13043474号-2</a>
    </div>
    <div class="line" v-if="version==='yxye'">
      备案号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #222; margin-left:5px;">沪ICP备13043474号-3</a>
    </div>
    <div class="line" v-if="version==='pubmedop'">
      备案号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #222; margin-left:5px;">沪ICP备13043474号-6</a>
    </div>
    <div class="qrcode">
      <img src="../assets/images/qrcode-yxyehh-1.png" alt="qrcode">
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="stylus">
.jyh-footer
  position relative
  font-size 12px
  font-weight 300
  color #666
  background-color rgb(224,232,243)
  height 140px
  padding-top 15px
  padding-bottom 20px

.line
  height 35px
  line-height 35px

.line
  a
    color #666
    &:first-child
      margin-left 0

.qrcode
  position absolute
  right 11%
  top 25px
  width 120px
  height 120px

.qrcode
  img
    display block
    width 100%
    height 100%
</style>
